import React from "react";
import styled from "styled-components";
import { MdKeyboardArrowUp } from "react-icons/md";
function ScrollTop() {
  const ScrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <WrapperScrollTop onClick={ScrollToTop}>
      <img src="./ic.svg" alt="" />
    </WrapperScrollTop>
  );
}
const WrapperScrollTop = styled.div`
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: blue;
  position: fixed;
  z-index: 999;
  top: 90%;
  right: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default ScrollTop;
