import React, { useState } from 'react';
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";
import {
  Nav,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  NavLink,
} from 'reactstrap';

function Block8(props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (

    <Box_home>
    <Container>
    <H3tiel>Everything you need no know <br />  </H3tiel>
    <div class="list-faq0g">
      <div className="line-wfq">
          <p class="h90">What is Gostak AI?</p><p class="mth-27"><img src="./rw.svg" alt="" /></p>
          <p></p>
      </div>
      <div className="line-wfq">
          <p class="h90">How does AI enhance mining and reduce energy consumption?</p><p class="mth-27"><img src="./rw.svg" alt="" /></p>
          <p></p>
      </div>
      <div className="line-wfq">
          <p class="h90">Can I use rented GPUs for purposes other than mining?</p><p class="mth-27"><img src="./rw.svg" alt="" /></p>
          <p></p>
      </div>
      <div className="line-wfq">
          <p class="h90">What AI-algorithms and plans do you offer for cloud mining?</p><p class="mth-27"><img src="./rw.svg" alt="" /></p>
          <p></p>
      </div>
      <div className="line-wfq">
          <p class="h90"> What are the benefits of using Gostak  AI for 3D rendering and gaming?</p><p class="mth-27"><img src="./rw.svg" alt="" /></p>
          <p></p>
      </div>
      <div className="line-wfq">
          <p class="h90">How do I start with Gostak  AI?</p><p class="mth-27"><img src="./rw.svg" alt="" /></p>
          <p></p>
      </div>
    </div>

    </Container>
          </Box_home>
  );
}



const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    padding: 65px 0;
    z-index:1;
    p{
      color: #fff;
    }

    @media (max-width: 770px) {
      padding:15px 0;
    }

    .line-wfq{
      margin-top:25px;
      border: solid 1px #1d1b71;
      padding: 15px;
      border-radius: 20px;
      display: flex;
      justify-content: space-between;
      background: rgb(90 89 89 / 15%);
      align-items: center;
      width: 100%;
      margin-bottom: 20px;

      p{
        margin-bottom: 0;
      }
      .h90{
        width:90%;
      }
      .mth-27{
        max-width:22px;
        width:100%;
        img{
          width:100%;
        }
      }
    }
`;



const H3tiel = styled.h3`
  font-size: 56px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #ffffff;
  text-align:center;
  font-family: "Kalam", cursive;
`;

export default Block8;