import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from "./view/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useWindowScroll } from "react-use";

const App = () => {
    let [menuBg, setMenuBg] = useState(false);
    const { y: pageOffset } = useWindowScroll();
    useEffect(() => {
        if (pageOffset > 70) {
            setMenuBg(true);
        } else {
            setMenuBg(false);
        }
    }, [pageOffset]);
    return (
        <StyledScreen>
            <Router>
                {/* <Header styleBg={menuBg} /> */}
                <Switch>
                    <Route path="/" exact>
                        <Home />
                    </Route>
                </Switch>
            </Router>
            {/* <WrapperFooter>
                <Footer />
            </WrapperFooter> */}
        </StyledScreen>
    );
};
const StyledScreen = styled.div`
    position: relative;

`;
const WrapperFooter = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default App;
