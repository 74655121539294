import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";
// import ProgressBar from 'react-bootstrap/ProgressBar'

class Block1 extends Component {



    render() {
        return (
            <Box_home>
            <Container className="zdf">
              <div className="top">
                <ul className="list-head">
                  <li>
                    <a href="/"><img className="lghome" src="./xiu.svg" alt="" /></a>
                  </li>
                 <li>
                    <a target="_blank" href="https://x.com/BabyYodaX"><img className="s67" src="./xiux.svg" alt="" /></a>
                    <a target="_blank" href="https://t.me/BabyYodaXWorld"><img className="s67" src="./xiutg.svg" alt="" /></a>
                  </li>
                </ul>
              </div>



                <Groupdp>
                  <img style={{ maxWidth: '100%' }} src="./g1321317307.png" alt="" />
                </Groupdp>


            </Container>
            <img className="abst" src="./via.svg" alt="" />
          </Box_home>
        );
    }
}




const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    padding: 0;
    z-index:1;
    background-position: center;
    /* background:url('./chuk.png'); */
    background-repeat: no-repeat;
    min-height:100vh;
    /* background-size: cover; */
    z-index:1;
    background-position: center center;
    @media (max-width: 1025px) {
      min-height:50vh;
    }
    @media (max-width: 570px) {
      min-height:30vh;
      background-position: center;
      background-size:cover;
    }


    .zdf{
      z-index:1!important;
      position: relative!important;
    }
    .abst{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      pointer-events: none;

      @media (max-width: 770px) {

      }
    }
    .lghome{
      max-width:200px;
      @media (max-width: 770px) {
        max-width:90px;
      }
      @media (max-width: 570px) {
        max-width:45px;
      }
    }

    .list-head{
      list-style:none;
      padding:15px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      flex-wrap: wrap;
      @media (max-width: 770px) {
        padding:5px 15px;
      }
    }

    .s67{
        max-width:60px;
        width:100%;
        margin-right:7px;
        @media (max-width: 770px) {
          max-width:40px;
        }
        @media (max-width: 570px) {
          max-width:25px;
        }
      }


`;


const GroupAbout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 770px) {
    flex-wrap: wrap;
  }
`

const BUys = styled.div`
  width: 140px;
  margin: 0 auto;
  border-radius: 20px;
  text-align: center;
  border: solid 2px #ffffff;

  a{
    color:#fff;
    font-size:22px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 40px;
  }
`;


const GroupDep = styled.div`
    display: flex;
    align-items: center;
    border: solid 1px #fff;
    border-radius: 35px;
    padding:5px;
    margin-bottom: 26px;
    color: #fff;
    max-width: 660px;
    margin: auto;
    margin-bottom: 35px;
    width: 100%;


  @media (max-width: 770px) {

  }
  @media (min-width: 1200px) {
    border:none;
    box-shadow:none;
    background:unset;
    position: absolute;
    bottom: 5%;
    right: 10%;
  }
`




const Groupdp = styled.div`
  position: relative;
  padding-top:30px;
  text-align:center;
  @media (max-width: 770px) {
    width:100%;
  }
  .cat-depos{
    margin-bottom:0;
    font-weight: bold;
    color: #ffffff;
    font-size:21px!important;
  }

`



const Thumbnail = styled.div`
  max-width:450px;
  width: 100%;
  margin-right:45px;

  img{
    width:100%;
  }

  @media (max-width: 770px) {
    max-width:100%;
    margin-right:0;
  }
`

const Title_item = styled.h3`
    font-size: 24px;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 15px;
    color: #99378e;
`;

const Line_total = styled.p`
    color: #3d3d3d;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pra-total {
        font-weight: bold;
        font-size: 16px;
    }
`;

export default Block1;
