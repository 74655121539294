import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

class Block4 extends Component {
    render() {
        return (
            <Box_home id="thf">
            <Container>
            <div className="gkl40">
              <img src="./s673.svg" alt="" />
            </div>
            {/* <GroupVd>
              <div className="gkl20">
                <img src="./sleft.svg" alt="" />
              </div>
              <div className="dvideo">
                <video width="360" height="500" autoplay autoplay controls>
                  <source src="./sbvd.mp4" type="video/mp4" />
                </video>
              </div>
            </GroupVd> */}
            <Nav>
              <ul>
                  <li><a target="_blank" href="https://t.me/BabyYodaXWorld"><img src="./f1.svg" alt="" /></a></li>
                  <li><a target="_blank" href="https://x.com/BabyYodaX"><img src="./f2.svg" alt="" /></a></li>
                  <li><a target="_blank" href="https://www.geckoterminal.com/solana/pools/DznsR76d3FoPWZpeRmXXUAEv538hNVkw11DbKroc5TJV"><img src="./f3.svg" alt="" /></a></li>
                  <li><a target="_blank" href="/"><img src="./f4.svg" alt="" /></a></li>
                  <li><a target="_blank" href="https://dexscreener.com/solana/dznsr76d3fopwzpermxxuaev538hnvkw11dbkroc5tjv"><img src="./f5.svg" alt="" /></a></li>
                  <li><a target="_blank" href="/"><img src="./f6.svg" alt="" /></a></li>
                  <li><a target="_blank" href="/"><img src="./f7.svg" alt="" /></a></li>
                  <li><a target="_blank" href="/"><img src="./f8.svg" alt="" /></a></li>
              </ul>
            </Nav>

            </Container>
          </Box_home>
        );
    }
}




const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    padding: 65px 0;
    z-index:1;
    background:url('./sub.png');
    background-size: cover;
    background-position: center;
    min-height:80vh;
    background-repeat: no-repeat;
    p{
      color: #A0AEE5;
    }

    @media (max-width: 770px) {
      padding:15px 0;
    }
    .gkl40{
      text-align:center;
      img{
        max-width:90%;
      }
    }
`;



const GroupVd = styled.div`
  display:flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 2rem;
  align-items: center;

  .dvideo{
    max-width: 400px;
    width: 100%;
    overflow: hidden;
    margin: auto;
    border-radius: 15px;

    video{
      scale: 2.1;
      border-radius:15px;
    }
  }

  .gkl20{
    text-align: center;
    max-width: calc(100% - 415px);
    @media (max-width: 770px) {
      max-width:100%;
    }
  }


`;



const Nav = styled.div`
  float: right;
  position: relative;
  margin-top: 25px;

  ul{
    list-style: none;
    display: flex;
    align-items: center;
    gap: 10px;

    li{
      max-width:100px;
      width:100%;

      a{
        :hover{
          opacity:.5;
        }
      }
    }
  }

  @media (max-width: 770px) {

  }
`;



export default Block4;
