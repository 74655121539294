import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";
// import ProgressBar from 'react-bootstrap/ProgressBar'

class Blocktk extends Component {
    render() {
        return (
            <Box_home id="alopp">
            <Container>
                {/* <H3tiel>TOKENOMICS  <br /> </H3tiel>
                <div className="gkl40">
                  <img src="./vtnk.png" alt="" />
                </div> */}
                <ul className="list-socials">
                    <li>
                        <a href="/"><img src="./ah1.png" alt="" /></a>
                    </li>
                    <li>
                        <a href="/"><img src="./ah2.png" alt="" /></a>
                    </li>
                    <li>
                        <a href="/"><img src="./ah3.png" alt="" /></a>
                    </li>
                    <li>
                        <a href="/"><img src="./ah4.png" alt="" /></a>
                    </li>
                    <li>
                        <a href="/"><img src="./ah5.png" alt="" /></a>
                    </li>
                    <li>
                        <a href="/"><img src="./ah6.png" alt="" /></a>
                    </li>
                    <li>
                        <a href="/"><img src="./ah7.png" alt="" /></a>
                    </li>

                </ul>
            </Container>

          </Box_home>
        );
    }
}




const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    padding: 0 0 35px 0;
    /* min-height:90vh; */
    background:url('./bnup.png');
    background-size: contain;
    background-position: center;
    z-index:1;
    background-repeat: no-repeat;

    .t0ch{
      text-align:center;
      color: #A0AEE5;
    }
    .list-socials{
      display: flex;
      list-style: none;
      width: 100%;
      gap: 10px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding:25px 10px;
      li{
        width:24%;
        text-align:center;
        @media (max-width: 770px) {
          width: 30%;
        }
    }
  }
`;


const Grhb3 = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap:30px;
  margin-bottom:25px;
  @media (max-width: 770px) {

  }

  .ct0{
    width:31%;
    text-align:center;
    background: rgb(182 186 191 / 12%);
    border-radius: 15px;
    padding: 20px;
    border: solid 1px #152152;
    @media (max-width: 770px) {
    width:100%
    }

    .i0btw{
      margin-bottom:10px;
    }

    p{
      color: #A0AEE5;
      text-align: justify;

    }
    h4{
      color: #ECF0FF;
      font-size:24px;
    }

  }
`




const H3tiel = styled.h3`
  font-size: 56px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #ffffff;
  text-align:center;
  font-family: "Kalam", cursive;
`;

const BUys = styled.div`
  background: linear-gradient(178.25deg, #03CFFF 17.89%, #0052FF 98.52%);
  max-width:235px;
  width:100%;
  margin:0 auto;
  border-radius:20px;
  text-align:center;

  a{
    color:#fff;
    font-size:26px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 55px;
  }
`;



export default Blocktk;
