import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";
// import ProgressBar from 'react-bootstrap/ProgressBar'

class Block3 extends Component {
    render() {
        return (
            <Box_home>
              <Nav>
                <ul>
                    <li><a target="_blank" href="https://t.me/BabyYodaXWorld"><img src="./ho1.svg" alt="" /></a></li>
                    <li><a target="_blank" href="https://x.com/BabyYodaX"><img src="./ho2.svg" alt="" /></a></li>
                    <li><a target="_blank" href="https://www.geckoterminal.com/solana/pools/DznsR76d3FoPWZpeRmXXUAEv538hNVkw11DbKroc5TJV"><img src="./ho3.svg" alt="" /></a></li>
                    <li><a href="/"><img src="./ho4.svg" alt="" /></a></li>
                    <li><a htarget="_blank" href="https://dexscreener.com/solana/dznsr76d3fopwzpermxxuaev538hnvkw11dbkroc5tjv"><img src="./ho5.svg" alt="" /></a></li>
                    <li><a href="/"><img src="./ho6.svg" alt="" /></a></li>
                    <li><a href="/"><img src="./ho7.svg" alt="" /></a></li>
                    <li><a href="/"><img src="./ho8.svg" alt="" /></a></li>
                </ul>
              </Nav>
                <div className="ifk8">
                  <img src="./s98.png" alt="" />
                </div>
                <div className="ifk9">
                  <img src="./hkx.png" alt="" />
                </div>
                <div className="ifk8">
                  <img src="./s7h.png" alt="" />
                </div>
                <div className="ifk8">
                  <img src="./sbb.png" alt="" />
                </div>
                <div className="ifk8">
                  <img src="./shb.png" alt="" />
                </div>
          </Box_home>
        );
    }
}




const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    z-index:1;
    padding: 0 0 35px 0;
    background:url('./vf2.png');
    background-size: cover;
    background-position: center;
    min-height:80vh;
    background-repeat: no-repeat;

    .ifk8{
      text-align:center;
      margin-top:10rem;
      margin:auto;
      img{
        max-width:80%;
      }
    }

    .ifk9{
      text-align: center;
      margin-bottom:20px;
      img{
        max-width:40%;

        @media (max-width: 770px) {
          max-width:70%;
        }
      }
    }

`;



const Nav = styled.div`
  float: right;
  position: relative;
  margin-top: 25px;

  ul{
    list-style: none;
    display: flex;
    align-items: center;
    gap: 10px;

    li{
      max-width:100px;
      width:100%;

      a{
        :hover{
          opacity:.5;
        }
      }
    }
  }

  @media (max-width: 770px) {

  }
`;



const H3tiel = styled.h3`
  font-size: 56px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #ffffff;
  text-align:center;
  font-family: "Kalam", cursive;
`;


export default Block3;
