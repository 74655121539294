import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";
// import ProgressBar from 'react-bootstrap/ProgressBar'

class Block6 extends Component {



    render() {
        return (
            <Box_home>
                  <div className="gkl40">
                    <img src="./ho6.png" alt="" />
                </div>
                <GroupAbout>
                    <ul>
                        <li><a href="/"><img src="./fave.png" alt="" /></a></li>
                        <li><a href="/"><img src="./chx.svg" alt="" /></a></li>
                        <li><a href="/"><img src="./chtg.svg" alt="" /></a></li>
                    </ul>
                </GroupAbout>
                <div className="gkl40">
                    <img src="./ls.svg" alt="" />
                </div>
          </Box_home>
        );
    }
}




const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    padding: 0 0 40px 0;
    z-index:1;
    /* background:url('./blog1w.svg'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 770px) {
      padding:25px 0;
    }

    .gkl40{
      text-align:center;
      img{
        max-width:55%;
        @media (max-width: 770px) {
          max-width:100%;
        }
      }

      @media (max-width: 770px) {
          padding:10px;
        }
    }

`;


const GroupAbout = styled.div`
  display: flex;
  align-items: center;
  margin-top:2.5rem;
  justify-content:center;
  ul{
    display: flex;
    align-items: center;
    gap:25px;
    list-style:none;
    li{
      max-width:150px;
      width:100%;

      a{
        :hover{
          opacity:.5;
        }
      }
    }
  }

  @media (max-width: 770px) {
    padding: 15px;
  }
`

const GroupDep = styled.div`
    display: flex;
    align-items: center;
    border: solid 1px #fff;
    border-radius: 35px;
    padding:5px;
    margin-bottom: 26px;
    color: #fff;
    max-width: 660px;
    margin: auto;
    margin-bottom: 35px;
    width: 100%;


  @media (max-width: 770px) {

  }
  @media (min-width: 1200px) {
    border:none;
    box-shadow:none;
    background:unset;
    position: absolute;
    bottom: 5%;
    right: 10%;
  }
`




const Content = styled.div`
  position: relative;
  width: calc(100% - 645px);
  color:#fff;

  @media (max-width: 770px) {
    width:100%;
  }

`


const Groupdp = styled.div`
  position: relative;

  @media (max-width: 770px) {
    width:100%;
  }
  .cat-depos{
    margin-bottom:0;
    font-weight: bold;
    color: #ffffff;
    font-size:21px!important;
  }

`



const Thumbnail = styled.div`
  max-width:450px;
  width: 100%;
  margin-right:45px;

  img{
    width:100%;
  }

  @media (max-width: 770px) {
    max-width:100%;
    margin-right:0;
  }
`

const Title_item = styled.h3`
    font-size: 24px;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 15px;
    color: #99378e;
`;

const Line_total = styled.p`
    color: #3d3d3d;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pra-total {
        font-weight: bold;
        font-size: 16px;
    }
`;

export default Block6;
