import React, { Component } from "react";
import styled from "styled-components";
import CountUp from "react-countup";
import { Container, Row, Col } from "reactstrap";
// import ProgressBar from 'react-bootstrap/ProgressBar'

class Map extends Component {
    render() {
        return (
            <Box_home>
            <Container>
                <div className="bottom-f7h">
                  <a href="/"><img src="./xiu.svg" alt="" /></a>
                  <div className="groupyd">
                    <a className="i1" href="/"><img src="./yoda.png" alt="" /></a>
                    <a target="_blank" href="https://x.com/BabyYodaX"><img className="mg50" src="./xiux.svg" alt="" /></a>
                    <a target="_blank" href="https://t.me/BabyYodaXWorld"><img className="mg50" src="./xiutg.svg" alt="" /></a>
                    <p className="i2">© 2024 by BABY YODA. All rights reserved!</p>
                  </div>

                </div>
            </Container>
          </Box_home>
        );
    }
}




const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    z-index:1;
    padding: 65px 0 0 0;

    .groupyd{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 10px;

      .i1{
        width:100%;
        text-align: end;
      }
      .i2{
        width:100%;
        text-align: end;
      }
    }

    .bottom-f7h{
      display:flex;
      align-items: center;
      gap:15px;
      justify-content: center;
      p{
        margin-bottom:0;
        font-size:32px;
        font-family: "Kalam", cursive;
        color:#ffffff;
      }
      .mg50{
        max-width:50px;
      }
    }

`;




export default Map;
