import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

class Block5 extends Component {
    render() {
        return (
            <Box_home>
            <Container>
            <H3tiel> <img src="./rns.svg" alt="" /><br /> </H3tiel>
            <GroupDs></GroupDs>
            <div className="gkl40">
              <img src="./w90.png" alt="" />
            </div>
            <div className="gkl41">
              <img src="./su78.svg" alt="" />
            </div>

            <p></p>
            </Container>
          </Box_home>
        );
    }
}




const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    z-index:1;
    padding: 55px 0;

    @media (max-width: 770px) {
      padding:25px 0;
    }

    .gkl40{
      text-align:center;
    }

    .gkl41{
      text-align:center;
      img{
        max-width:85%;
        margin:auto;

        @media (max-width: 770px) {
          max-width:90%;
        }

      }

      @media (max-width: 770px) {
      padding:10px;
      }
    }
`;


const H3tiel = styled.h3`
  font-size: 56px;
  font-weight: 400;
  margin-bottom: 2rem;
  color: #ffffff;
  text-align:center;

  img{
    width:40%;
  }
`;



const GroupDs = styled.div`
  display:flex;

  img{
    width:40%;
  }
`;



export default Block5;
